
import { translations } from '@/mixins'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate";
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
@Component({
  components: {
    ValidationObserver
  },
  mixins: [translations]
})
export default class ChangeCodeModal extends Vue {
  private tempCode: String = ''
  @Prop() open!: Boolean
  @Prop() code!: String
  
  @Watch('code', {immediate: true, deep: true})
  onChangeCode() {
    this.tempCode = this.code
  }

  public translate!: Function

  get isOpen(): Boolean {    
    return this.open
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)
  }

  submit() {
    this.$emit('submit', this.tempCode)
  }
}
