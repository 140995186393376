import store from '@/store'

export default function admin({ to, from, next }) {


    let isLoggedIn = store.getters['account/isLoggedIn']
    let getConceptSettings = store.getters['account/getConceptSettings']
    let getUserRole = store.getters['account/getUserRole']
    let subscription = store.getters['account/getSubscription']
    let paymentId = store.getters['account/getSubscriptionPaymentId']
    let getUserClient = store.getters['account/getUserClient']
    let getActiveConcept = store.getters['account/activeConcept']


    if (isLoggedIn && getConceptSettings && getUserRole.includes('administrator')) {
        // for foodics login only
        if (getConceptSettings.attributes['billing-type'] === 'foodics') {
            next()
        } else {
            if (getConceptSettings.attributes['billing-type'] === 'stripe' && !getConceptSettings.attributes['is-free-package'] && subscription === null) {
                if (!paymentId) {
                    return next('/customer-subscription')
                } else {
                    return next(`/payment/${paymentId}`)
                }
            } else if (getConceptSettings.attributes['billing-type'] === 'stripe' && !getConceptSettings.attributes['is-free-package'] && subscription) {
                if (subscription.attributes['status'] !== 'active') {
                    // demo with rangie
                    return next('/account')
                } else if (subscription.attributes.status === 'incomplete' && !paymentId) {
                    return next('/customer-subscription')
                } else if (subscription.attributes.status === 'incomplete' && paymentId) {
                    return next(`/payment/${paymentId}`)
                }
            }
        }
    }


    let disabledPagesArr = [];
    let pagesArr = []
    let buttonArr = []
    let user = JSON.parse(localStorage.getItem('SOLO')).account.userRole
    let premiumConcepts = [1, 2, 5, 10, 11, 12, 13, 15, 17, 18, 27, 86, 228, 145, 167, 123, 241, 29, 101, 102, 103, 109, 227, 229, 330] // concept_id
        // let allowedConceptPromotion = [] // concept_id
    let currentConceptId = store.getters['account/activeConcept'].id
        // upon login capture accessible page base on role and start the restriction
        // also check if the current selected concept id does not exist on the allowed concepts

    // workaround fix for production
    // in staging it is call_center but in production it is call center
    user[0] = user[0].replace(' ', '_');
    // console.log('getUserClient: ', getUserClient);


    // switch (subscription?.attributes?.name) {
    //     case 'Standard':
    //       disabledPagesArr = ['reports_general', 'reports_orders', 'reports_locations', 'reports_customers', 'reports_products', 'promotions', 'curbside']
    //       // disabledPagesArr = ['dashboard', 'sliding-banners', 'menus', 'locations', 'kiosks', 'apps', 'qr-ordering', 'customers', 'employees', 'feedback', 'reports_general', 'orders', 'curbside', 'promotions', 'messaging', 'settings', 'switch']
    //       store.commit('page/setDisabledPage', disabledPagesArr)
    //       break
    // }

    if(subscription === null) { //fixes
        disabledPagesArr = []
        store.commit('page/setDisabledPage', disabledPagesArr)

    } else {
        switch (subscription?.attributes?.name) {
            case 'Standard':
              disabledPagesArr = ['reports_general', 'reports_orders', 'reports_locations', 'reports_customers', 'reports_products', 'segments', 'curbside']
              store.commit('page/setDisabledPage', disabledPagesArr)
              break
        }
    }


    switch (user[0]) {
        case 'administrator':
            pagesArr = ['whatsapp', 'account', 'dashboard', 'qr-ordering', 'order-statuses', 'messaging', 'messaging_order_statuses', 'blacklists', 'sliding-banners', 'kiosks', 'curbside', 'apps', 'cms', 'notification', 'menus', 'loyalty', 'locations', 'devices', 'feedback', 'orders', 'customers', 'promotions', 'segments', 'employees', 'settings', 'reports_general', 'customer-subscription', 'subscription-meals', 'gmb']
            buttonArr = ['new_slider', 'add_slide', 'edit_slide', 'remove_slide', 'add_menu', 'edit_category', 'delete_category', 'open_category', 'add_item', 'edit_item', 'delete_item', 'open_item', 'add_modGroup_to_item', 'remove_modGroup_from_item', 'open_modGroup', 'add_ingredient_to_item', 'remove_ingredient_from_item', 'add_cusField_to_item', 'edit_cusField_in_item', 'add_modItem_to_modifier', 'edit_modItem', 'remove_modItem_from_modifier', 'sync_pos', 'add_category', 'add_location', 'sync_location', 'edit_location', 'disable_location', 'delete_location', 'export_orders', 'order_status', 'order_details', 'order_location', 'order_driver', 'new_customer', 'export_customers', 'customer_details', 'new_segment', 'export_segments', 'segment_details', 'segment_customers', 'new_employee', 'employee_details', 'delete_employee', 'add_field', 'delete_field', 'add_group', 'group_details', 'add_ingredient', 'ingredient_details', 'feedback_attachment']


            if (!premiumConcepts.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'segments' || page !== 'reports_general')
            }

            // if (!allowedConceptPromotion.includes(currentConceptId)) {
            //     pagesArr = pagesArr.filter((page) => page !== 'promotions')
            // }
            // if (to.path === '/smiles' && getActiveConcept.label !== 'Kudu') {
            //     next({ name: 'dashboard' })
            // }

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            next()
            break
        case 'restaurant':
            pagesArr = ['locations', 'store locations', 'orders', 'order-details', 'segments', 'upsells', 'menus', 'MenuCategory', 'updatecategory', 'items', 'updatecategory', 'itemlist', 'itemCreate', 'itemModifier', 'EditModgroupItem', 'modifier', 'modifierGroupItem', 'MenuUpsells', 'curbside']
            buttonArr = ['new_slider', 'add_slide', 'edit_slide', 'remove_slide', 'edit_location', 'order_status', 'order_details', 'order_location', 'order_driver', 'new_employee', 'employee_details', 'delete_employee', 'add_field', 'delete_field', 'add_group', 'group_details', 'add_menu', 'open_category', 'add_item', 'open_item', 'add_modGroup_to_item', 'remove_modGroup_from_item', 'open_modGroup', 'add_ingredient_to_item', 'remove_ingredient_from_item', 'add_cusField_to_item', 'edit_cusField_in_item', 'add_modItem_to_modifier', 'edit_modItem', 'remove_modItem_from_modifier', 'sync_pos', 'add_category', 'enable_disable_category', 'include_exclude_modifier', 'enable_disable_modifier_group', 'enable_disable_modifier']

            if (!premiumConcepts.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'segments')
            }

            // if (!allowedConceptPromotion.includes(currentConceptId)) {
            //     pagesArr = pagesArr.filter((page) => page !== 'promotions')
            // }
            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name) || disabledPagesArr.includes(to.name)) {
                next({ name: 'orders' })
            }
            next()
            break

        case 'franchisee':
            pagesArr = ['qr-ordering','curbside','menus', 'MenuCategory', 'updatecategory', 'items', 'updatecategory', 'itemlist', 'itemCreate', 'itemModifier', 'EditModgroupItem', 'modifier', 'modifierGroupItem', 'locations', 'store locations', 'orders', 'franchisee', 'reports_general', 'reports_orders', 'reports_locations', 'reports_customers', 'reports_products']
            buttonArr = ['new_slider', 'add_slide', 'edit_slide', 'remove_slide', 'edit_location', 'order_status', 'order_details', 'order_location', 'order_driver', 'new_employee', 'employee_details', 'delete_employee', 'add_field', 'delete_field', 'add_group', 'group_details', 'add_menu', 'open_category', 'add_item', 'open_item', 'add_modGroup_to_item', 'remove_modGroup_from_item', 'open_modGroup', 'add_ingredient_to_item', 'remove_ingredient_from_item', 'add_cusField_to_item', 'edit_cusField_in_item', 'add_modItem_to_modifier', 'edit_modItem', 'remove_modItem_from_modifier', 'sync_pos', 'add_category', 'enable_disable_category', 'include_exclude_modifier', 'enable_disable_modifier_group', 'enable_disable_modifier']

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)

            if (!pagesArr.includes(to.name)) {
                next({ name: 'orders' })
            }
            next()
            break
        case 'call_center':
            pagesArr = ['locations', 'store locations', 'feedback', 'feedback-list', 'loyalty-item', 'customers', 'list', 'loyalty-item', 'orders', 'order-details', 'upsells', 'curbside', 'promotions', 'PromotionsList', 'CouponGroupsList', 'CouponGroupsCouponList', 'CouponGroupsCouponCreate', 'CouponGroupsCouponEdit']
            buttonArr = ['new_slider', 'add_slide', 'edit_slide', 'remove_slide', 'order_status', 'order_details', 'order_location', 'order_driver', 'new_customer', 'export_customers', 'customer_details', 'new_segment', 'export_segments', 'segment_details', 'segment_customers', 'new_employee', 'employee_details', 'delete_employee', 'add_field', 'delete_field', 'add_group', 'group_details']
                // if (!allowedConceptPromotion.includes(currentConceptId)) {
                //     pagesArr = pagesArr.filter((page) => page !== 'promotions')
                // }

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name) || disabledPagesArr.includes(to.name)) {
                next({ name: 'orders' })
            }
            next()
            break
        case 'supervisor':
            pagesArr = ['locations', 'store locations', 'feedback', 'feedback-list', 'loyalty-item', 'orders', 'order-details', 'customers', 'list', 'details', 'segments', 'upsells', 'subscription-meals']
            buttonArr = ['new_slider', 'add_slide', 'edit_slide', 'remove_slide', 'edit_location', 'export_orders', 'order_status', 'order_details', 'order_location', 'order_driver', 'new_customer', 'export_customers', 'customer_details', 'new_segment', 'export_segments', 'segment_details', 'segment_customers', 'new_employee', 'employee_details', 'delete_employee', 'add_field', 'delete_field', 'add_group', 'group_details', 'feedback_attachment']

            if (!premiumConcepts.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'segments')
            }

            // if (!allowedConceptPromotion.includes(currentConceptId)) {
            //     pagesArr = pagesArr.filter((page) => page !== 'promotions')
            // }

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name) || disabledPagesArr.includes(to.name)) {
                next({ name: 'orders' })
            }
            next()
            break
        case 'reports':
            pagesArr = ['dashboard', 'reports_general', 'reports_orders', 'reports_locations', 'reports_customers', 'reports_products']
            if (!premiumConcepts.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'reports_general' || page !== 'reports_orders' || page !== 'reports_locations' || page !== 'reports_customers' || page !== 'reports_products')
            }
            store.commit('page/setPage', pagesArr)
            if (!pagesArr.includes(to.name) || disabledPagesArr.includes(to.name)) {
                next({ name: 'dashboard' })
            }
            next()
            break
        case 'marketing':
            pagesArr = ['dashboard', 'menus', 'MenuCategory', 'updatecategory', 'items', 'updatecategory', 'itemlist', 'itemCreate', 'itemModifier', 'EditModgroupItem', 'modifier', 'modifierGroupItem', 'promotions', 'PromotionsList', 'CouponGroupsList', 'CouponGroupsCouponList', 'messaging', 'messaging_notification', 'feedback', 'feedback-list', 'customers', 'list', 'details', 'loyalty-item', 'sliding-banners']
            buttonArr = ['new_slider', 'add_slide', 'edit_slide', 'remove_slide', 'new_segment', 'export_segments', 'segment_details', 'segment_customers', 'feedback_attachment']

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name) || disabledPagesArr.includes(to.name)) {
                next({ name: 'dashboard' })
            }
            next()
            break
        case 'menu':
            pagesArr = ['menus', 'MenuCategory', 'itemModifier', 'EditModgroupItem', 'modifier', 'modifierGroupItem']
            buttonArr = ['new_slider', 'add_slide', 'edit_slide', 'remove_slide', 'new_segment', 'export_segments', 'segment_details', 'segment_customers', 'feedback_attachment', 'add_menu', 'open_category', 'enable_disable_category', 'add_item', 'open_item', 'enable_disable_item', 'add_modGroup_to_item', 'remove_modGroup_from_item', 'open_modGroup', 'add_ingredient_to_item', 'remove_ingredient_from_item', 'add_cusField_to_item', 'edit_cusField_in_item', 'add_modItem_to_modifier', 'edit_modItem', 'remove_modItem_from_modifier', 'sync_pos', 'add_category', 'enable_disable_modifier_group', 'enable_disable_modifier']

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name) || disabledPagesArr.includes(to.name)) {
                next({ name: 'menus' })
            }
            next()
            break
        case 'driver':
            pagesArr = ['locations', 'store locations', 'orders', 'order-details', ]
            buttonArr = ['new_slider', 'add_slide', 'edit_slide', 'remove_slide', 'new_employee', 'employee_details', 'delete_employee', 'add_field', 'delete_field', 'add_group', 'group_details']

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name) || disabledPagesArr.includes(to.name)) {
                next({ name: 'orders' })
            }
            next()
            break
    }

    switch (getUserClient?.attributes?.label) {
        case 'Foodics Online':
          if(getConceptSettings.attributes['is-free-package']) {
            pagesArr = ['add-menu', 'dashboard', 'sliding-banners', 'menus', 'MenuCategory', 'apps', 'list', 'edit-app', 'create-app', 'qr-ordering','settings','SettingsConcepts', 'itemCreate', 'itemModifier', 'modifier', 'modifierGroupItem']
            // disabledPagesArr = ['account', 'order-statuses', 'messaging', 'messaging_order_statuses', 'blacklists', 'kiosks', 'curbside', 'apps', 'notification', 'loyalty', 'locations', 'devices', 'feedback', 'orders', 'customers', 'promotions', 'segments', 'employees', 'settings', 'reports_general', 'customer-subscription', 'subscription-meals']

                store.commit('page/setPage', pagesArr)
                // store.commit('page/setDisabledPage', disabledPagesArr)
                if (!pagesArr.includes(to.name)) {
                    next({ name: 'dashboard' })
                }
            } else {
            //   disabledPagesArr = ['reports_general', 'promotions']............
            //   store.commit('page/setDisabledPage', disabledPagesArr)
          }

          break
      }

    next()

}
