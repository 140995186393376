
import { Select, Option } from "element-ui";
import Modal from "@/components/Modal.vue";
import { translations } from '@/mixins';
import Translations from './Translations.vue';
import {Banner as BannerApi, Category, Item} from "@/services/SOLO";
import {
  BanAttributes,
  SlideAttributes,
  Banner,
  Slide as SlideModel,
} from "@/models";
import { Imagetype } from "@/interfaces/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler } from "@/mixins";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import { ElNotification } from "element-ui/types/notification";
import { required, email } from "vee-validate/dist/rules";
import {mapGetters} from 'vuex';
import ItemTimedEvents from "./timedevents/ItemTimedEvents.vue";

interface objKey {
  [x: string]: string;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
    ItemTimedEvents,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: 'account/getConceptSettings',
    }),
  },
  mixins: [eventHandler,translations],
})
export default class Slide extends Vue {
  bannerslides: Array<SlideModel> = [];
  mID: any = "";
  advanceSettings: any = false;
  applyBoth: Boolean = false;
  private arrayLang: Array<string> = [];
  bbanDesc: objKey = {};
  bbanLinkLabel: objKey = {};
  bbanImgUri: objKey = {};
  bbanTitle: objKey = {};
  imageType: any = {};
  imageValuePrimary: string = '';
  imageValueSecondary: string = '';
  // imageValue: any = {};
  tmpImageValuePrimary: string = '';
  tmpImageValueSecondary: string = '';
  // tmpImageValue: string = "";
  fileValue: any = {};
  $notify: any;
  linkOptions: Array<any> = [];
  menus: Array<Slide> = [];
  numOfLoadedData: number = 0;
  file: any;
  waiting!: Function;
  restore!: Function;
  public translate!: Function;
  private isLoaded: boolean = false;
  getLocale!: any;
  getLanguages!: Function;
  getConceptSettings: any;
  tab: number = 1;

  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload Image",
    },
  ];

  linkTypes: Array<Object> = [
    {
      value: "item",
      text: "Item",
    },
    {
      value: "category",
      text: "Category",
    },
    {
      value: "url",
      text: "URL",
    },
    {
      value: "cms-page",
      text: "CMS-PAGE",
    },
  ];
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    // file: any;
    // file1: any;
    // file2: any;
  };
  confirm!: Function;


  @Watch("file", { immediate: true, deep: true })
  onFileChange(val: any) {

  }

  created() {
    this.linkTypes = [
      {
        value: "item",
        text: this.translate("Item"),
      },
      {
        value: "category",
        text: this.translate("Category"),
      },
      {
        value: "url",
        text: this.translate("URL"),
      },
      {
        value: "cms-page",
        text: this.translate("CMS-PAGE"),
      },
    ];
  }

  @Watch('bannerSlides', {immediate: true, deep: true})
  onBannerSlidesChange(newVal: Boolean) {
    console.log('bannerSlides: ', newVal);
    if (newVal) {
    }
  }

  @Watch("fileValue", {immediate: true, deep: true})
  checkPrimaryUpload(newVal: any) {
    if (newVal[this.getConceptSettings.attributes['primary-language']]) {
      if(this.applyBoth) {
        if(this.fileValue[this.getConceptSettings.attributes['primary-language']]) {
          console.log('check ckeck pri', this.fileValue[this.getConceptSettings.attributes['primary-language']]);
          this.fileValue[this.getConceptSettings.attributes['secondary-language']] = this.fileValue[this.getConceptSettings.attributes['primary-language']];
        }
      }

    } else {
      if(this.applyBoth) {
        if(this.fileValue[this.getConceptSettings.attributes['secondary-language']]) {
          console.log('check ckeck sec', this.fileValue[this.getConceptSettings.attributes['secondary-language']]);
          this.fileValue[this.getConceptSettings.attributes['primary-language']] = this.fileValue[this.getConceptSettings.attributes['secondary-language']];
        } 

      }
    }
  }


  @Watch("imageValuePrimary", {immediate: true, deep: true})
  checkPrimary(newVal: any) {
    if (newVal) {
      if(this.applyBoth) {
        this.imageValueSecondary = this.imageValuePrimary;
        console.log('check apply primary', this.imageValueSecondary);
      }
      
    }
  }

  @Watch("imageValueSecondary", {immediate: true, deep: true})
  checkSecondary(newVal: any) {
    if (newVal) {
      if(this.applyBoth) {
        this.imageValuePrimary = this.imageValueSecondary;
        console.log('check apply secondary', this.imageValuePrimary);
      }
    }
  }
  

  mounted() {
    console.log("check data banner", this.bannerslides);
    console.log('upload img secondary lang', this.getConceptSettings.attributes['secondary-language']);

    /* @ts-ignore */
    if(this.getConceptSettings.attributes['primary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    }

    /* @ts-ignore */
    if(this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }
    console.log('arrayLang', this.arrayLang);
    // for (let i in this.arrayLang) {
    //   this.imageValue[this.arrayLang[i]] = "";
    // }
    this.imageValuePrimary = "";
    this.imageValueSecondary = "";

    this.initializeForms();
    this.getMenus();
  }

  initializeForms() {
    for (let i in this.arrayLang) this.findSlide(this.arrayLang[i]);
  }

  getMenus() {
    BannerApi.menus().then((response: any) => {
      this.menus = response.data.data;
      if (this.bannerslides
        && Array.isArray(this.bannerslides)
        && this.bannerslides.length
      ) {
        this.onMenuSearch();
      }
    });
  }

  checkSize(text: any) {

    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }

  }



  onMenuSearch(): void {
    if (this.bannerslides
      && Array.isArray(this.bannerslides)
      && this.bannerslides.length
    ) {
      this.onChangeLinkType();
    }
  }

  onChangeLinkType(): void {
    const { attributes } = this.bannerslides[0];

    if (this.isLoaded) {
      this.bannerslides[0].attributes['link-value'] = '';
    } else {
      this.isLoaded = true;
    }

    if (attributes['link-type'] === 'item') {
      if (attributes['link-value']) {
        Item.getItem(attributes['link-value'])
          .then((response) => {
            if (response?.data?.data?.links?.self) {
              this.mID = response?.data?.data?.links?.self.split('menus/')[1].split('/categories')[0];
              BannerApi.linkValueSearch(this.mID, 'items', 1)
                .then((response: any) => this.linkOptions = response.data.data);
            }
          });
      } else {
        this.linkValueSearchfunc(this.mID, 'items', 1);
      }
    } else if (attributes['link-type'] === 'category') {
      if (attributes['link-value']) {
        Category.integrationCategories()
          .then((response) => {
            const categories = response.data.data;
            const category = categories.find((category: any) => Number(category.id) === Number(attributes['link-value']));

            this.mID = category.menu_id.toString();
            return BannerApi.linkValueSearch(this.mID, 'categories', 1);
          })
          .then((response) => (this.linkOptions = response.data.data));
      } else {
        BannerApi.linkValueSearch(this.mID, 'categories', 1)
          .then((response) => (this.linkOptions = response.data.data));
      }
    }
  }

  linkValueSearchfunc(mId: any, item: any, page: any) {
      BannerApi.linkValueSearch(this.mID, 'items', page)
        .then((response: any) =>  {

          if (response?.data?.data && Array.isArray(response?.data?.data)) {
            this.linkOptions = [...this.linkOptions, ...response?.data?.data];
          }

          if (
              response.data.meta.pagination.current_page <
              response.data.meta.pagination.total_pages
          ) {
            this.linkValueSearchfunc(mId, item, response.data.meta.pagination.current_page + 1);
          }
          console.log("total items", this.linkOptions);
          this.$forceUpdate();

        });
  }

  findSlide(lang: string) {
    BannerApi.findSlide(
      this.$route.params.banner_id,
      this.$route.params.slide_id,
      lang
    ).then((response: any) => {
      let data = { ...response.data.data, ...{ lang: lang } };
      this.bannerslides.push(data);
      console.log(this.bannerslides)

      this.imageType[lang] = 0;
      if (this.numOfLoadedData == 0) {
        this.imageValuePrimary = data.attributes['image-uri'];
      } else if (this.numOfLoadedData == 1){
        this.imageValueSecondary = data.attributes['image-uri'];
      }
      this.numOfLoadedData++;
    });
  }

  updateSlide(e: HTMLFormElement, isvalid: boolean) {
    if (!isvalid) {
      // if (!this.imageType) {
      //   const defaultText = e.submitter.innerHTML;
      //   this.waiting(
      //     e,
      //     '<i class="fas fa-spinner fa-spin"></i> Saving changes'
      //   );
      //   BannerApi.update(
      //     this.setData(),
      //     this.$route.params.banner_id,
      //     this.$route.params.slide_id
      //   )
      //     .then((response: any) => {
      //       this.restore(e, defaultText);
      //       this.$notify({
      //         title: "UPDATES SAVED!",
      //         verticalAlign: "bottom",
      //         horizontalAlign: "left",
      //         message: "Changes have been saved successfully",
      //         type: "success",
      //         icon: "fas fa-check",
      //       });
      //       this.$router.go(-1);
      //     })
      //     .catch((err: any) => {
      //       this.$notify({
      //         title: "SYSTEM ERROR!",
      //         verticalAlign: "bottom",
      //         horizontalAlign: "left",
      //         message: err.response.data.error.detail,
      //         type: "danger",
      //         icon: "fas fa-bomb",
      //       });

      //     });
      // } else {
        const defaultText = e.submitter.innerHTML;
        this.waiting(
          e,
          '<i class="fas fa-spinner fa-spin"></i> Saving changes'
        );
        BannerApi.updateFormdata(
          this.formatData(),
          this.$route.params.banner_id,
          this.$route.params.slide_id
        )
          .then((response: any) => {
            for (let i in this.arrayLang) this.imageType[i] = 0;
            this.bannerslides[0].attributes["image-uri"] =
              response.data.data.attributes["image-uri"];
            this.restore(e, defaultText);
            this.$notify({
              title: "UPDATES SAVED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Changes have been saved successfully",
              type: "success",
              icon: "fas fa-check",
            });
            this.$router.go(-1);
          })
          .catch((err: any) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
      // }
    }
  }

  formatData() {
    // console.log('', this.$refs.file);
    let formData = new FormData();
    formData.append(
      "display-order",
      this.bannerslides[0].attributes["display-order"]
    );
    formData.append("link-type", this.bannerslides[0].attributes["link-type"]);
    formData.append(
      "link-value",
      this.bannerslides[0].attributes["link-value"]
    );
    // formData.append("label", this.bannerslides[0].attributes.label);

    for (let i in this.bannerslides) {
      // formData.append(
      //   `title[${this.bannerslides[i].lang}]`,
      //   this.bannerslides[i].attributes.title
      //     ? this.bannerslides[i].attributes.title
      //     : ""
      // );
      // formData.append(
      //   `description[${this.bannerslides[i].lang}]`,
      //   this.bannerslides[i].attributes.description
      //     ? this.bannerslides[i].attributes.description
      //     : ""
      // );

      // formData.append(
      //     `link-label[${this.bannerslides[i].lang}]`,
      //     this.bannerslides[i].attributes["link-label"]
      //     ? this.bannerslides[i].attributes["link-label"]
      //     : ""
      // );

      if (this.fileValue && this.fileValue[this.bannerslides[i].lang]) {
        console.log('fileValue', this.fileValue[this.bannerslides[i].lang]);
        /* @ts-ignore */
        if (this.bannerslides[i].lang == this.getConceptSettings.attributes['primary-language']) {
          formData.append("image", this.fileValue[this.bannerslides[i].lang]);
        } 
        /* @ts-ignore */
        if (this.bannerslides[i].lang == this.getConceptSettings.attributes['secondary-language']) {
          formData.append("alt-image", this.fileValue[this.bannerslides[i].lang]);
        }
      } else {
        /* @ts-ignore */
        formData.append(`image-uri[${this.getConceptSettings.attributes['primary-language']}]`, this.imageValuePrimary);
        /* @ts-ignore */
        formData.append(`image-uri[${this.getConceptSettings.attributes['secondary-language']}]`, this.imageValueSecondary);
        // formData.append(`image-uri[${this.bannerslides[i].lang}]`, this.imageValue[this.bannerslides[i].lang]);
      }
    }
    
    return formData;
  }

  resetImage(type: number, lang: string) {
    if (type == 0) {
      /* @ts-ignore */
      if (this.getConceptSettings.attributes['primary-language'] == lang) {
        this.imageValuePrimary = this.tmpImageValuePrimary;
      /* @ts-ignore */
      } else if (this.getConceptSettings.attributes['secondary-language']) {
        this.imageValueSecondary = this.tmpImageValueSecondary;
      }
      // this.imageValue[lang] = this.tmpImageValue;
      this.fileValue[lang] = null;
    } 
    if (type == 1) {
      /* @ts-ignore */
      if (this.getConceptSettings.attributes['primary-language'] == lang) {
        this.tmpImageValuePrimary = this.imageValuePrimary;
        this.imageValuePrimary = '';
      /* @ts-ignore */
      } else if (this.getConceptSettings.attributes['secondary-language'] == lang) {
        this.tmpImageValueSecondary = this.imageValueSecondary;
        this.imageValueSecondary = '';
      }
      // this.tmpImageValue = this.imageValue[lang];
      // this.imageValue[lang] = null;
    }
  }

  cancel() {
    this.$router.go(-1)
  }

  // formData() {
  //   let formData = new FormData();
  //   formData.append(
  //     "display-order",
  //     this.bannerslides[0].attributes["display-order"]
  //   );
  //   formData.append("link-type", this.bannerslides[0].attributes["link-type"]);
  //   formData.append(
  //     "link-value",
  //     this.bannerslides[0].attributes["link-value"]
  //   );
  //   formData.append("label", this.bannerslides[0].attributes.label);

  //   for (let i in this.bannerslides) {
  //     formData.append(
  //       `title[${this.bannerslides[i].lang}]`,
  //       this.bannerslides[i].attributes.title
  //         ? this.bannerslides[i].attributes.title
  //         : ""
  //     );
  //     formData.append(
  //       `description[${this.bannerslides[i].lang}]`,
  //       this.bannerslides[i].attributes.description
  //         ? this.bannerslides[i].attributes.description
  //         : ""
  //     );
  //   }

  //   formData.append("image", this.$refs.file1.files[0]);
  //   formData.append("alt-image", this.$refs.file2.files[0]);
  //   return formData;
  // }

  // setData() {
  //   for (let i in this.bannerslides) {
  //     this.bbanTitle[this.bannerslides[i].lang] = this.bannerslides[
  //       i
  //     ].attributes.title;
  //     this.bbanDesc[this.bannerslides[i].lang] = this.bannerslides[
  //       i
  //     ].attributes.description;
  //     this.bbanLinkLabel[this.bannerslides[i].lang] = this.bannerslides[
  //       i
  //     ].attributes["link-label"];
  //     this.bbanImgUri[this.bannerslides[i].lang] = this.bannerslides[
  //       i
  //     ].attributes["image-uri"];
  //   }

  //   return {
  //     "display-order": this.bannerslides[0].attributes["display-order"],
  //     "link-type": this.bannerslides[0].attributes["link-type"],
  //     "link-value": this.bannerslides[0].attributes["link-value"],
  //     label: this.bannerslides[0].attributes.label,
  //     link: this.bannerslides[0].attributes.link,
  //     title: this.bbanTitle,
  //     description: this.bbanDesc,
  //     "link-label": this.bbanLinkLabel,
  //     "image-uri": this.bbanImgUri,
  //   };
  // }

  fileChange(e: any, errors: any) {
    if (typeof e.target.files[0] != undefined) {
      this.file = e.target.files[0];

    }
  }
}
