
import { translations, eventHandler } from "@/mixins";
import Translations from "./Translations.vue";
import { FadeTransition, ScaleTransition } from "vue2-transitions";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import moment from "moment";
import { LoadingPanel } from "@/components";
import { Location } from "@/models";
import { Location as LocationService } from '@/services/SOLO';

interface ojbKey {
  [x: string]: string;
}

interface locationObject {
  id: string;
  name: ojbKey;
  status: string;
  telephone: string;
  pos: string;
  email: string;
  country: string;
  lat: string;
  long: string;
  "delivery-enabled": Boolean;
  "pickup-enabled": Boolean;
  "curbside-enabled": Boolean;
  "open-24-hours": Boolean;
  "is-inventory-sync-enabled": Boolean;
  "is-drive-thru-enabled": Boolean;
  "has-prompt-for-table-number": Boolean;
  "delivery-charge": string;
  "logistics": any;
  "logistic-selection-type": any;
  line1: ojbKey;
  "promised-time-delta": ojbKey;
  "opening-hours": Array<any>;
}


@Component({
  components: {
    FadeTransition,
    ScaleTransition,
    Translations,
    LocationInfoForm: () => import("./LocationInfoForm"),
    LocationUpdate: () => import("../LocationUpdate"),
  },
  mixins: [translations, eventHandler],
})
export default class LocationModal extends Vue {
  @Prop() location!: any;
  @Prop() hasOcimsIntegration!: any;
  public open: boolean = false;
  $notify: any;
  confirm!: Function;
  translate!: Function;
  getLanguages!: Function

  private arrayLang: Array<string> = [];
  private gmapModal: Boolean = false;
  private saving: Boolean = false;

  created() {
    this.arrayLang = this.getLanguages()
  }

  mounted() {
    console.log('hasOcimsIntegration3', this.hasOcimsIntegration)
  }

  @Watch('open', { immediate: true, deep: true })
  onOpen(value: Boolean) {
    if(!value) {
      this.open = false;
      this.$emit("close");
    }
  }

  private cancel() {
    this.open = false;
    // this.$emit("close");
  }

  close() {
    this.open = false;
    // this.$emit("close");
  }

  private onLocationCreated(location: Location) {
    this.$emit("location:created", location);
  }

  private onLocationFailed(modal: any) {
    this.open = modal
  }

  private onLocationUpdated(location: Location) {
    this.$emit("location:updated", location);
  }

  private onLocationDeleted(location: Location) {
    this.$emit("location:deleted", location);
  }
}
