var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isOpeningHoursSet)?_c('location-modal',{ref:"locationInfoFormModal",attrs:{"location":_vm.location,"locationInfoFormModal":_vm.locationInfoFormModalKey,"hasOcimsIntegration":_vm.hasOcimsIntegration},on:{"close":_vm.onModalClose,"location:created":_vm.refreshList,"location:updated":_vm.refreshList,"location:deleted":_vm.refreshList}}):_vm._e(),_c('temporary-delivery-modal',{ref:"temporaryDeliveryFormModal",attrs:{"location":_vm.location,"temporaryDeliveryFormModal":_vm.temporaryDeliveryFormModalKey},on:{"prepareMapForAddingNewDeliveryArea":_vm.prepareMapForAddingNewDeliveryArea}}),_c('change-area-name-modal',{attrs:{"open":_vm.areaNameModal,"area-name":_vm.activeDeliveryAreaName},on:{"submit":_vm.updateAreaName,"modal:update":_vm.updateAreaNameModalState}}),_c('change-code-modal',{attrs:{"open":_vm.codeModal,"code":_vm.activeDeliveryCode},on:{"submit":_vm.updateCode,"modal:update":_vm.updateCodeModalState}}),_c('b-container',{staticClass:"mt--6 location-map",staticStyle:{"padding-left":"0 !important","padding-right":"0 !important"},style:(_vm.isDisplay === true ? 'overflow-y: true' : ''),attrs:{"fluid":""}},[_c('b-row',{staticClass:"mb-3 pb-2",class:_vm.getLocale === 'en_US'
          ? 'location-map-checkbox'
          : 'location-map-checkbox-rtl'},[_c('b-col',{staticClass:"col-auto",class:{ 'ml-auto': _vm.getLocale === 'en_US' }},[_c('small',{staticClass:"font-weight-bold text-white"},[_vm._v(_vm._s(_vm.translate("Show in map:")))])]),_c('b-col',{staticClass:"col-auto"},[_c('b-form-checkbox',{staticClass:"mr-4",model:{value:(_vm.enable.branches),callback:function ($$v) {_vm.$set(_vm.enable, "branches", $$v)},expression:"enable.branches"}},[_c('translate',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v("Branches")]),_c('small',{staticClass:"font-weight-bold text-white"},[_vm._v(_vm._s(_vm.translate("Branches")))])],1)],1),_c('b-col',{staticClass:"col-auto"},[_c('b-form-checkbox',{staticClass:"mr-4",model:{value:(_vm.enable.delivery),callback:function ($$v) {_vm.$set(_vm.enable, "delivery", $$v)},expression:"enable.delivery"}},[_c('translate',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v("Delivery Areas")]),_c('small',{staticClass:"font-weight-bold text-white"},[_vm._v(_vm._s(_vm.translate("Delivery Areas")))])],1)],1),_c('b-col',{staticClass:"col-auto"},[_c('b-form-checkbox',{staticClass:"mr-5",model:{value:(_vm.enable.drivers),callback:function ($$v) {_vm.$set(_vm.enable, "drivers", $$v)},expression:"enable.drivers"}},[_c('translate',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v("Drivers")]),_c('small',{staticClass:"font-weight-bold text-white"},[_vm._v(_vm._s(_vm.translate("Drivers")))])],1)],1)],1),_c('b-row',{staticClass:"location"},[_c('b-col',[_c('b-card',{staticClass:"border-0",staticStyle:{"margin-bottom":"0","border-radius":"0"},attrs:{"no-body":""}},[(_vm.isDisplay)?_c('confirm-card',{ref:"confirmCard",class:_vm.getLocale === 'ar_SA'
                ? 'location-confirm_ar'
                : 'location-confirm_en',attrs:{"is-adding":_vm.isAdding,"active-delivery-area":_vm.activeDeliveryArea,"is-saving":_vm.isSaving,"delivery-area-is-updating":_vm.deliveryAreaIsUpdating,"hasOcimsIntegration":_vm.hasOcimsIntegration},on:{"edit:coordinates":_vm.initUpdate,"edit:name":_vm.updateAreaNameModalState,"edit:code":_vm.updateCodeModalState,"update:coordinates":_vm.updateCoordinates,"cancel:updates":_vm.resetDefault,"submit:area":_vm.saveNewArea,"delete:coordinates":_vm.deleteArea}}):_c('confirm-card',{ref:"confirmCard",class:_vm.getLocale === 'ar_SA'
                ? 'mobile-location-confirm_ar'
                : 'mobile-location-confirm_en',attrs:{"is-adding":_vm.isAdding,"active-delivery-area":_vm.activeDeliveryArea,"is-saving":_vm.isSaving,"delivery-area-is-updating":_vm.deliveryAreaIsUpdating,"hasOcimsIntegration":_vm.hasOcimsIntegration},on:{"edit:coordinates":_vm.initUpdate,"edit:name":_vm.updateAreaNameModalState,"edit:code":_vm.updateCodeModalState,"update:coordinates":_vm.updateCoordinates,"cancel:updates":_vm.resetDefault,"submit:area":_vm.saveNewArea,"delete:coordinates":_vm.deleteArea}}),_c('card',{staticClass:"location-controller bg-secondary",class:_vm.isDisplay === false ? 'location-controller-mobile' : '',style:({
              height:
                _vm.isDisplay === true
                  ? this.height + 95 + 'px'
                  : this.height - 475 + 'px',
            }),attrs:{"body-classes":"p-3"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{class:[
                  _vm.getLocale === 'en_US' || _vm.getLocale === 'fr_FR'
                    ? 'text-left'
                    : 'text-right',
                ],attrs:{"lg":"6"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.translate("Locations")))])]),(
                  _vm.getConceptSettings.attributes['billing-type'] == 'stripe' ||
                  _vm.getConceptSettings.attributes['billing-type'] == 'foodics'
                )?_c('b-col',{class:[
                  _vm.getLocale === 'en_US' || _vm.getLocale === 'fr_FR'
                    ? 'text-right'
                    : 'text-left',
                ],attrs:{"sm":"6"}},[_c('small',{staticClass:"mr-1",class:[_vm.getLocale !== 'en_US' ? 'float-left' : ''],staticStyle:{"margin-top":"4.3px"},attrs:{"id":"locationsLoaded"}},[(_vm.getLocale !== 'en_US')?_c('span',[_vm._v(_vm._s(_vm.getSubscription && (_vm.getSubscription.attributes["branch-quantity"] || _vm.getSubscription.attributes.quantity))+" / "+_vm._s(_vm.activeBranches))]):_c('span',[_vm._v(_vm._s(_vm.activeBranches)+" / "+_vm._s(_vm.getSubscription && (_vm.getSubscription.attributes["branch-quantity"] || _vm.getSubscription.attributes.quantity)))])]),_c('small',{staticClass:"text-gray"},[_vm._v(_vm._s(_vm.translate("Licences active")))])]):_vm._e()],1),_c('div',{staticClass:"p-3 row"},[_c('div',{class:_vm.getLocale === 'en_US' ? 'mr-1' : 'ml-1'},[(_vm.getUserRole[0] != 'franchisee')?_c('base-button',{attrs:{"disabled":(_vm.getConceptSettings.attributes['billing-type'] ==
                      'stripe' ||
                      _vm.getConceptSettings.attributes['billing-type'] ==
                        'foodics') &&
                    _vm.getSubscription &&
                    (_vm.activeBranches >=
                      _vm.getSubscription.attributes['branch-quantity'] ||
                      _vm.activeBranches >= _vm.getSubscription.attributes.quantity),"id":"addNewLoc"},on:{"click":function($event){return _vm.addNewLocation()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-fat-add"})]),_c('translate',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v("ADD NEW")]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(_vm._s(_vm.translate("ADD NEW")))])],1):_vm._e()],1),_c('div',[(
                    _vm.getUserRole[0] != 'franchisee' &&
                    _vm.enableSyncLocation.includes(
                      _vm.getConceptSettings.attributes['default-pos']
                    )
                  )?_c('base-button',{staticClass:"ml-auto",attrs:{"type":"primary"},on:{"click":function($event){return _vm.syncLocations()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-sync"})]),_c('translate',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v("SYNC LOCATION")]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(_vm._s(_vm.translate("SYNC LOCATION")))])],1):_vm._e()],1)]),_c('card',{staticClass:"p-0",attrs:{"body-classes":"p-1"}},[_c('translate',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v("Search Location")]),_c('search-item',{attrs:{"placeHolder":_vm.translate('Search Location')},on:{"change":_vm.filterLocation}}),(_vm.loadingLocation)?_c('div',{staticClass:"d-flex align-items-center"},[_c('strong',[_vm._v("Loading...")]),_c('div',{staticClass:"spinner-border ml-auto",attrs:{"role":"status","aria-hidden":"true"}})]):_c('location-items',{attrs:{"locations":_vm.filteredLocations},on:{"pan":_vm.pan,"enable":_vm.enableStatus,"location:update":_vm.onLocationUpdate,"location:temporaryDelivery":_vm.addTemporaryDelivery,"remove":_vm.deleteLocation}})],1),_c('b-row',{staticClass:"mt--2"},[_c('b-col',{class:[
                  _vm.getLocale === 'en_US' || _vm.getLocale === 'fr_FR'
                    ? 'text-left'
                    : 'text-right',
                ],attrs:{"lg":"6"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.translate("Drivers")))])]),_c('b-col',{class:[
                  _vm.getLocale === 'en_US' || _vm.getLocale === 'fr_FR'
                    ? 'text-right'
                    : 'text-left',
                ],attrs:{"sm":"6"}},[_c('small',{staticClass:"mr-1",class:[_vm.getLocale !== 'en_US' ? 'float-left' : ''],staticStyle:{"margin-top":"4.3px"},attrs:{"id":"driversLoaded"}},[(_vm.getLocale !== 'en_US')?_c('span',[_vm._v(_vm._s(_vm.totalDriversCount)+" / "+_vm._s(_vm.drivers.length))]):_c('span',[_vm._v(_vm._s(_vm.drivers.length)+" / "+_vm._s(_vm.totalDriversCount))])]),_c('small',{staticClass:"text-gray"},[_vm._v(_vm._s(_vm.translate("Drivers loaded")))])])],1),_c('card',{staticClass:"mt-2",attrs:{"body-classes":"p-2"}},[_c('translate',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v("Search Drivers")]),_c('search-item',{attrs:{"placeHolder":_vm.translate('Search Drivers')},on:{"change":_vm.filterDriver}}),_c('driver-items',{attrs:{"drivers":_vm.filteredDrivers,"included":_vm.driversIncluded},on:{"pan":_vm.pan,"show:driver":_vm.showDriver}})],1)],1),_c('div',{style:({
              height:
                _vm.isDisplay === true
                  ? this.height + 105 + 'px'
                  : this.height - 475 + 'px',
            }),attrs:{"id":"map-custom"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }