
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { Applications } from '@/services/SOLO'
import { translations } from '@/mixins'
import { mapGetters } from 'vuex';

@Component({
  computed: {
    ...mapGetters({
      getUserClient: 'account/getUserClient',
    }),
  },
  mixins: [translations]
})
export default class AppItems extends Vue {
  applications: Array<Object> = []
  translate!: Function
  getUserClient!: any;
  clientLabel: string = ''
  mounted() {
    this.getAppItems()
  }

  async created() {
    if (window.location.host.includes('foodicsonline') || window.location.host.includes('foodics')) {
      this.clientLabel = 'Foodics Online';
    }
  }

  public get getSoloAppUrl() {
    return this.getUserClient?.attributes?.app?.['logo-url'] || 'https://cdn.getsolo.io/1637743137619dfa21d2367_solo.app.png';
  }

  public get getKioskUrl() {
    return this.getUserClient?.attributes?.kiosk?.['logo-url'] || '	https://cdn.getsolo.io/1663324380632450dcc0655_solo-k.png';
  }

  public get getSoloWebUrl() {
    return this.getUserClient?.attributes?.['social-ordering']?.['logo-url'] || 'https://cdn.getsolo.io/1637743042619df9c2c07e0_solo.web.png';
  }

  public get getClientUserLabel() {
    return this.getUserClient?.attributes?.label;
  }

  getAppItems() {
    Applications.getAllApplications()
        .then((response: any) => {
          this.applications = response.data.data
          console.log('this.applications: ', this.applications)
          console.log('getUserClient: ', this.getUserClient);
        })
  }
}
