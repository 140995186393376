
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { SettingsFacilities, SettingsConcepts } from "@/services/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Concept } from "@/models";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
import { eventHandler } from "@/mixins";
import { translations } from '@/mixins';
import Translations from '../Translations.vue';
import {mapGetters, mapMutations} from 'vuex'
import ChangeLogs from './components/concepts/ChangeLogs'
import _ from "lodash";
import { defineComponent } from 'vue';  // component reference link: https://icon-sets.iconify.design/

defineComponent({
  name: 'MaterialSymbolsDownloadSharp',
});


interface OpeningHourObject {
  day: string;
  open: string;
  closed: string;
}

@Component({
  components: {
    ConceptsOpeningHours: () =>
      import("./components/concepts/ConceptsOpeningHours.vue"),
    ConceptsOpeningHoursLocation: () =>
      import("./components/concepts/ConceptsOpeningHoursLocation.vue"),
    ConceptsImageLogo: () =>
      import("./components/concepts/ConceptsImageLogo.vue"),
    ConceptsImageSizes: () =>
      import("./components/concepts/ConceptsImageSizes.vue"),
    ConceptsSupportedOrderTypes: () =>
      import("./components/concepts/ConceptsSupportedOrderTypes.vue"),
    ConceptsPrices: () => import("./components/concepts/ConceptsPrices.vue"),
    ConceptsTimes: () => import("./components/concepts/ConceptsTimes.vue"),
    ConceptsOthers: () => import("./components/concepts/ConceptsOthers.vue"),
    ConceptFinancial: () => import("./components/concepts/ConceptFinancial.vue"),
    ConceptOrders: () => import("./components/concepts/ConceptOrders.vue"),
    ConceptMarketing: () => import("./components/concepts/ConceptMarketing.vue"),
    ConceptDangerZones: () => import("./components/concepts/ConceptDangerZones.vue"),
    ConceptContacts: () => import("./components/concepts/ConceptContacts.vue"),
    ConceptPrivacyPolicy: () => import("./components/concepts/ConceptPrivacyPolicy.vue"),
    VueTimepicker,
    LoadingPanel: () => import("@/components/LoadingPanel"),
    Translations,
    ChangeLogs
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getLocales: 'app/getLocales',
      activeConcept: 'account/activeConcept',
    }),
  },
  methods: {
    ...mapMutations({
      setUserConcept: 'account/setUserConcept',
      setLocale: 'app/setLocale',

    })
  },
  mixins: [translations, eventHandler],
})
export default class Concepts extends Vue {
  setUserConcept!: Function;
  activeConcept!: Concept
  getLocale!: Function;
  getLocales!: Function;
  translate!: Function;
  itemLimit: Number = 100;
  loadingConcept: Boolean = false;
  conceptLoaded: Boolean = false;
  saving: Boolean = false;  
  confirm!: Function;
  data: string = "";
  concept: Concept = {
    id: "",
    type: "",
    links: {},
    attributes: {
      "currency-format": "",
      "supported-order-types": "",
      "supported-payment-types": "",
      "deliver-payment-types": "",
      "pickup-payment-types": "",
      "opening-hours": "",
      "created-at": "",
      "updated-at": "",
      "languages": "",
      label: "",
      country: "",
      "dialing-code": "",
      "currency-code": "",
      "currency-symbol": "",
      "logo-uri": "",
      "logo-image": "",
      "default-opening-hours": "",
      "default-delivery-charge": "",
      "default-pos": "",
      "default-promised-time-delta-delivery": "",
      "default-promised-time-delta-pickup": "",
      "default-minimum-order-amount": "",
      "special-delivery-price": "",
      "order-cancellation-time": "",
      "order-cancellation-max-status": "",
      "order-price-calculation": "",
      "vat-rate": "",
      "vat-type": "inclusive",
      "recommended-dimensions": {
        item: {
          width: "",
          height: "",
        },
        category: {
          width: "",
          height: "",
        },
        slider: {
          width: "",
          height: "",
        },
      },
      "delivery-promised-time": "",
      "pickup-promised-time": "",
      "is-inventory-sync-enabled": false,
      "allow-order-cancellation-before-promised-time": false,
      "is-maintenance-mode": false,
      "is-2fa-enabled": false,
      "cart-expiry-minutes": "",
      "feedback-redirect-url": "",
      "decimal-places": "",
      "rounding-mode": "",
      "rounding-precision": "",
      "can-cancel-payment": false,
      "minimum-order-amount-delivery": "",
      "maximum-order-amount-delivery": "",
      "minimum-order-amount-pickup": "",
      "maximum-order-amount-cash": "",
      "minimum-order-amount-for-special-delivery": "",
      "tax-id": "",
      "max-days-allowed-in-advance-order": "",
      "default-schedule-delivery-time": "",
      "allow-advance-order": false,
      "is-cart-notes-enabled": false,
      "default-order-status-cash": "",
      "default-order-status-card": "",
      "order-display-id-config": "",
      "kiosk-business-type": "",
      "is-auto-close-orders-daily": false,
      "is-menu-auto-sync": false,
      "is-optional-modifier-groups-hidden": false,
      "otp-length": "",
      "is-cart-items-notes-enabled": false,
      "primary-language": "",
      "secondary-language": "",
      "abandoned-cart-alert": "0",
      "call-center-number": "",
    },
  };
  openingHours: Array<Object> = [];
  $notify: any;
  $refs!: {
    imageSizes: any;
  };
  marketings: any = {};
  financials: any = {};
  orders: any = {};
  contacts: any = {};
  dangers: any = {};
  async getConcepts() {
    this.loadingConcept = true;
    try {
      const response = await SettingsConcepts.get();
      console.log(response.data.data);
      this.setConcept(response.data.data);
      this.loadingConcept = false;
      this.conceptLoaded = true;
      this.marketings = _.cloneDeep(response.data.data);
      this.financials = _.cloneDeep(response.data.data);
      this.orders = _.cloneDeep(response.data.data);
      this.contacts = _.cloneDeep(response.data.data);
      this.dangers = _.cloneDeep(response.data.data);
    } catch (error) {
      this.loadingConcept = true;
      this.conceptLoaded = false;
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }

  setConcept(data: any) {
    console.log('setConcept: ', data)
    this.concept = data;
    if (!this.concept.attributes['special-delivery-price']) {
      this.concept.attributes['special-delivery-price'] = 0;
    }
    this.concept.attributes['is-inventory-sync-enabled'] = !!this.concept.attributes['is-inventory-sync-enabled'];
    this.prepareDefaultOpeningHours();
  }

  prepareDefaultOpeningHours() {
    if (
      !this.concept.attributes["default-opening-hours"] ||
      this.concept.attributes["default-opening-hours"] === "None"
    ) {
      this.concept.attributes["default-opening-hours"] = JSON.stringify([
        { day: "0", open: "", closed: "" },
        { day: "1", open: "", closed: "" },
        { day: "2", open: "", closed: "" },
        { day: "3", open: "", closed: "" },
        { day: "4", open: "", closed: "" },
        { day: "5", open: "", closed: "" },
        { day: "6", open: "", closed: "" },
      ]);
    }

    let openingHours: Array<Object> = JSON.parse(
      this.concept.attributes["default-opening-hours"]
    );

    this.openingHours = openingHours.map((item: any) => {
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm").format("hh:mm A"),
        closed: moment(`${item.closed}`, "hh:mm").format("hh:mm A"),
      };
    });
  }

  resetChanges() {
    this.confirm(
      this.$bvModal,
      "Are you sure you want to reset your changes?"
    ).then((value: boolean) => {
      if (value) {
        this.getConcepts();
      }
    });
  }

  async saveChanges() {

    console.log("check upload iamge", this.marketings.attributes['logo-image']);
    // console.log(this.marketings.attributes['logo-image']['name'].split('.').pop().toString());
    console.log("check uri", this.marketings.attributes['logo-uri']);
    // return;

    
    if(!this.marketings.attributes['logo-uri']) {
      this.$notify({
        title: "WARNING!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Please upload a logo.",
        type: "warning",
        icon: "fas fa-bomb",
      });
      return;
    }

    // if(this.marketings.attributes['logo-image'] == undefined) {

    //   console.log("check logo", this.marketings.attributes['logo-image']);

    //   let extension = this.marketings.attributes['logo-uri'].split('.').pop();

    //   if(extension != 'jpg' &&  extension != 'png' && extension != 'jpeg') {
    //     this.$notify({
    //       title: "WARNING!",
    //       verticalAlign: "bottom",
    //       horizontalAlign: "left",
    //       message: `Please upload a valid extension!`,
    //       type: "warning",
    //       icon: "fas fa-bomb",
    //     });
    //     return;
    //   }


    // }



    this.saving = true;
    let openingHours: Array<Object> = this.openingHours.map((item: any) => {
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm A").format("HH:mm"),
        closed: moment(`${item.closed}`, "hh:mm A").format("HH:mm"),
      };
    });

    let formData = new FormData();
    let ignoreKeys = [
      "opening-hours",
      "supported-order-types",
      "supported-payment-types",
      "deliver-payment-types",
      "pickup-payment-types",
      "delivery-promised-time",
      "pickup-promised-time",
      "recommended-dimensions",
      "is-inventory-sync-enabled",
      "allow-order-cancellation-before-promised-time",
      "logo-uri",
      // "is-maintenance-mode",
      "languages",
      // "order-cancellation-max-status",
      "currency-code",
      "currency-code-translations",
      "currency-symbol-translations",
      "currency-format",
      "currency-format-translations",
      "custom-fields",
      "custom-field-map",
      "allowed-countries",
      "created-at",
      "updated-at",
    ];
    for (let [key, value] of Object.entries(this.concept.attributes)) {
      // if (!ignoreKeys.includes(key)) {
        // intended for 'default-opening-hours' in locations tab
        if (key == 'default-opening-hours') {
          if (value !== JSON.stringify(openingHours)) {
            /* @ts-ignore */
            formData.append(key, JSON.stringify(openingHours));
          }
          continue;
        }
        // loop through marketings tab
        for (let [key2, value2] of Object.entries(this.marketings.attributes)) {
          if (key2 == 'abandoned-cart-alert') {
            //
          } else {
            if (!value2) {
              if (key == key2 && value !== value2) {
                /* @ts-ignore */
                formData.append(key2, value2);
                continue;
              }
            }
          }

          if (key2 == 'logo-uri') {
            if (key == key2 && value != value2) {
              formData.append('logo-image', this.marketings.attributes['logo-image']);
            }
          } else {
            if (!ignoreKeys.includes(key2)) {
              if (key == key2 && value != value2) {
                /* @ts-ignore */
                formData.append(key2, value2);
              }
            }
          }
        }
        // loop through financials tab
        for (let [key2, value2] of Object.entries(this.financials.attributes)) {
          if (['can-cancel-payment', 'is-multi-payment-allowed'].includes(key2)) {
            value2 = value2 ? 1 : 0;  
          } else {
            if (!value2) {
              if (key == key2 && value !== value2) {
                /* @ts-ignore */
                formData.append(key2, value2);
                continue;
              }
            }
          }

          if (key2 == 'currency-format') {
            if (key == key2 && value != value2) {
              /* @ts-ignore */
              formData.append('currency-format', value2);
              if (this.getLocales.length > 0) {
                /* @ts-ignore */
                this.getLocales.forEach((lang: any) => {
                  /* @ts-ignore */
                  formData.append(`currency-format-translations[${lang.replace('_', '-').toLowerCase()}]`, value2);
                });
              } else {
                  /* @ts-ignore */
                formData.append(`currency-format-translations[${this.getLocale.replace('_', '-').toLowerCase()}]`, value2);
              }
            }
          } else {
            if (!ignoreKeys.includes(key2)) {
              if (key == key2 && value != value2) {
                /* @ts-ignore */
                formData.append(key2, value2);
              }
            }
          }
        }
        // loop through orders tab
        for (let [key2, value2] of Object.entries(this.orders.attributes)) {
          if (key2 == 'allow-advance-order' || key2 == 'is-cart-notes-enabled' || key2 == 'is-cart-items-notes-enabled') {
            value2 = value2 ? 1 : 0;
          } else {
            if (!value2) {
              if (key == key2 && value !== value2) {
                /* @ts-ignore */
                formData.append(key2, value2);
                continue;
              }
            }
          }

          if (!ignoreKeys.includes(key2)) {
            if (key == key2 && value != value2) {
              /* @ts-ignore */
              formData.append(key2, value2);
            }
          }
        }
        // loop through contacts tab
        for (let [key2, value2] of Object.entries(this.contacts.attributes)) {
          if (!value2) {
            if (key == key2 && value !== value2) {
              /* @ts-ignore */
              formData.append(key2, value2);
              continue;
            }
          }

          if (!ignoreKeys.includes(key2)) {
            if (key == key2 && value != value2) {
              /* @ts-ignore */
              formData.append(key2, value2);
            }
          }
        }
        // loop through dangers tab
        for (let [key2, value2] of Object.entries(this.dangers.attributes)) {
          if (key2 == 'is-menu-auto-sync' || key2 == 'is-auto-close-orders-daily' || key2 == 'is-maintenance-mode' || key2 == 'is-2fa-enabled' || key2 == 'is-optional-modifier-groups-hidden') {
            value2 = value2 ? 1 : 0;
          } else {
            if (!value2) {
              if (key == key2 && value !== value2) {
                /* @ts-ignore */
                formData.append(key2, value2);
                continue;
              }
            }
          }

          if (!ignoreKeys.includes(key2)) {
            if (key == key2 && value != value2) {
              /* @ts-ignore */
              formData.append(key2, value2);
            }
          }
        }
      // }
    }

    try {
      let response = await SettingsConcepts.update(this.concept.id, formData);
      let tmpAttributes = {
        id: this.concept.id,
        /* @ts-ignore */
        key: this.activeConcept.key,
        label: response.data.data.attributes.label
      }
      await this.setUserConcept(tmpAttributes);
      this.getConcepts();
      // this.setConcept(response.data.data);
      // console.log("from concept lang", response.data.data.attributes.languages);
      // this.$root.$emit('DashboardNavbar', response.data.data.attributes.languages);

      this.$notify({
        title: "CHANGES SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Changes successfully saved!",
        type: "success",
        icon: "fas fa-check",
      });
      // location.reload()
      this.saving = false;
    } catch (error) {
      this.saving = false;
      const errors: any = [];

      /* @ts-ignore */
      if (error.response.data
        /* @ts-ignore */
        && error.response.data.error
      ) {
        /* @ts-ignore */
        error.response.data.error.forEach((e: any) => {
          errors.push(e.detail);
        })
      }
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: errors.length && errors.join('\n') || "Something went wrong updating concept, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }

  mounted() {
    this.getConcepts();
  }
}
